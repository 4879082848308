import React, {Component} from 'react';

import { DollarOutlined, LogoutOutlined } from '@ant-design/icons';

import { BackTop, Layout, Menu, Avatar, Result, Button } from 'antd';
import {Link, BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {getStatusRequest, logoutRequest} from "./actions/authentication";

import './App.css';
import logo from './public/swichee_logo_2048p.png';

import Home from './routes/Home';

import SwicheeIntro from './routes/SwicheeIntro';
import UploadBlog from './routes/UploadBlog/UploadBlog';
import UploadImage from './routes/UploadImage/UploadImage';
import UploadVideo from './routes/UploadVideo/UploadVideo';
import UploadSound from './routes/UploadSound/UploadSound';
import {MyPage, Login} from './containers';
import ElecIcon from './components/elec.js';


const {Header, Content, Footer} = Layout;
const {SubMenu} = Menu;

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentKey: '1'
        };
        props.getStatusRequest();
        console.log(this.props, props);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
    }

    componentDidMount() {
        console.log(this.props);
        this.props.getStatusRequest();
    }

    handleHeaderClick(e) {
        this.setState({currentKey: '8'});
    }

    handleMenuClick = e => {
        e.key === 'logout' && this.props.handleLogoutRequest();
        !e.key.startsWith('sub') &&
        this.setState({
            currentKey: e.key,
        });
    };


    render() {
        console.log('App.js props: ', this.props);
        return (
            <Router>
                <Layout>
                    <BackTop/>
                    <Header style={{
                        position: 'fixed',
                        zIndex: 1,
                        width: '100%',
                        height: '80px',
                        backgroundColor: '#fff',
                    }}>
                        <Link to="/">
                        <img src={logo} alt="logo" style={{
                            height: '50px',
                            float: 'left',
                            margin: '15px',
                        }}
                        />
                        </Link>
                        <Menu mode="horizontal" style={{
                            lineHeight: '78px',
                            width: '100%',
                        }}
                              selectedKeys={[this.state.currentKey]}
                              onClick={this.handleMenuClick}
                        >
                            {/*<Menu.Item key="1"><span>회사 소개</span><Link to="/"/></Menu.Item>*/}
                            {/*<Menu.Item key="2"><span>스위치란?</span><Link to="/swichee-intro"/></Menu.Item>*/}
                                            {this.props.status.isLoggedIn ?
                                                <SubMenu title={
                                                    <span className="submenu-title-wrapper">
                              <Avatar size={36}
                                      style={{marginRight: 30}}
                                      src={this.props.data ? this.props.data.image : ''}/>
                                                        <span>{this.props.data.nickname}</span>
                            </span>
                                                } style={{float: 'right'}}
                                                >


                                                    <Menu.Item style={{
                                                        minWidth: 200,
                                                        minHeight: 60,
                                                        alignItems: "center",
                                                        display: 'flex'
                                                    }} key='sub_1'>
                                                        <Avatar style={{float: 'left', marginRight: 30}} size={50}
                                                                src={this.props.data ? this.props.data.image : ''}/>
                                                        <div style={{textAlign: 'center', alignItems: "center", display: 'flex'}}>
                                                            {this.props.data.name &&
                                                            <div>{this.props.data.name}</div>
                                                            }
                                                            <div>{this.props.data.nickname}</div>
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Divider/>
                                                    <Menu.Item style={{
                                                        minWidth: 200,
                                                        maxHeight: 40,
                                                        alignItems: "center",
                                                        display: 'inline-flex'
                                                    }} key='sub_2'>
                                                        <div style={{
                                                            width: '100%',
                                                            alignItems: "center",
                                                            display: 'inline-flex',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <div style={{
                                                                textAlign: 'left',
                                                                alignItems: "center",
                                                                display: 'inline-flex',
                                                            }}>
                                                                <ElecIcon active={true} color={"#eee"} backgroundColor={"#aaa"}
                                                                          size={30}
                                                                          style={{
                                                                              marginRight: 15
                                                                          }}/>
                                                                <span style={{
                                                                    textAlign: 'left',
                                                                }}>일렉</span>
                                                            </div>
                                                            <span style={{
                                                                textAlign: 'right',
                                                                marginRight: 30
                                                            }}>{this.props.data.coin / 10}</span>
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Item style={{
                                                        minWidth: 200,
                                                        maxHeight: 40,
                                                    }} key='sub_3' onClick={console.log(12313123)}>
                                                        <div style={{
                                                            width: '100%',
                                                            alignItems: "center",
                                                            display: 'inline-flex',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <div style={{
                                                                textAlign: 'left',
                                                                alignItems: "center",
                                                                display: 'inline-flex',
                                                            }}>
                                                                <DollarOutlined size={30} style={{fontSize: 30, marginRight: 15}} />
                                                                <span style={{
                                                                    textAlign: 'left',
                                                                    alignSelf: 'stretch'
                                                                }}>수익</span>
                                                            </div>
                                                            <span style={{
                                                                textAlign: "right",
                                                                marginRight: 30
                                                            }}>{this.props.data.profit / 10}</span>
                                                        </div>
                                                    </Menu.Item>
                                                    <Menu.Divider/>
                                                    <Menu.Item style={{
                                                        minWidth: 200,
                                                        maxHeight: 40,
                                                    }} key='logout'>
                                                        <div style={{
                                                            width: '100%',
                                                            alignItems: "center",
                                                            display: 'inline-flex',
                                                            justifyContent: 'end'
                                                        }}>
                                                            <LogoutOutlined size={30} style={{fontSize: 30, marginRight: 15}} />
                                                            <span style={{
                                                                textAlign: 'left',
                                                                alignSelf: 'stretch'
                                                            }}>로그아웃</span>
                                                        </div>
                                                    </Menu.Item>
                                                </SubMenu>
                                                :
                                                <Menu.Item key="7" style={{float: 'right'}}>
                                                    <span>로그인</span><Link
                                                    to="/login"/></Menu.Item>
                                            }
                            <SubMenu title="컨텐츠 업로드" style={{float: 'right'}}>
                                {/*<Menu.Item key="3">블로그 업로드<Link to="/upload-blog"/></Menu.Item>*/}
                                {/*<Menu.Item key="4">사진 업로드<Link to="/upload-image"/></Menu.Item>*/}
                                <Menu.Item key="5">비디오 업로드<Link to="/upload-video"/></Menu.Item>
                                {/*<Menu.Item key="6">사운드 업로드<Link to="/upload-sound"/></Menu.Item>*/}
                            </SubMenu>
                        </Menu>
                    </Header>
                    <Content style={{
                        marginTop: '80px',
                    }}>
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            <Route path="/swichee-intro" component={SwicheeIntro}/>
                            <Route path="/upload-blog"
                                   component={() => <UploadBlog isLoggedIn={this.props.status.isLoggedIn}/>}/>
                            <Route path="/upload-image"
                                   component={() => <UploadImage isLoggedIn={this.props.status.isLoggedIn}/>}/>
                            <Route path="/upload-video"
                                   component={() => <UploadVideo isLoggedIn={this.props.status.isLoggedIn}/>}/>
                            <Route path="/upload-sound"
                                   component={() => <UploadSound isLoggedIn={this.props.status.isLoggedIn}/>}/>
                            <Route path="/login" component={Login}/>
                            <Route path="/swichee-mypage" component={() => <MyPage currentUser={this.props.status.currentUser} isLoggedIn={this.props.status.isLoggedIn}/>}/>
                            <Route component={() => <Result
                                status="404"
                                title="404"
                                subTitle="Sorry, the page you visited does not exist."
                            />}/>
                        </Switch>
                    </Content>
                    < Footer style={{
                        textAlign: 'center',
                    }}>
                        Swichee 2020
                    </Footer>
                </Layout>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    console.log(state);
    return {
        status: state.authentication.status,
        data: state.authentication.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getStatusRequest: () => {
            return dispatch(getStatusRequest());
        },
        handleLogoutRequest: () => {
            return dispatch(logoutRequest());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
