import React, {Component} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider, Upload, Row, Col, Modal } from "antd";

const uploadButton = (
    <div>
        <PlusOutlined />
        <div className="upload-button-text">업로드</div>
    </div>
);

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ImageFirst extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: '',
            swicheeOffImageList: [],
            swicheeOnImageList: [],
        };
    }
    // state = {
    //     previewVisible: false,
    //     previewImage: '',
    //     swicheeOffImageList: [],
    //     swicheeOnImageList: [],
    // };

    handleCancel = () => {
        this.setState({ previewVisible: false });
    };

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleOffChange = ({ swicheeOffImageList }) => this.setState({ swicheeOffImageList });
    handleOnChange = ( swicheeOnImageList ) => this.setState({ swicheeOnImageList });

    render() {
        const swicheeOffUploadProps = {
            action: '//jsonplaceholder.typicode.com/posts/',
            listType: 'picture-card',
            multiple: true,
            defaultFileList: this.state.swicheeOffImageList,
            // onChange: info => {
            //     const status = info.file.status;
            //     if (status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }
            // },
            onChange: this.handleOffChange,
            onPreview: this.handlePreview,
        };
        const swicheeOnUploadProps = {
            action: 'https://www.mocky.io/v2/5185415ba171ea3a00704eed',
            listType: 'picture-card',
            multiple: true,
            defaultFileList: this.state.swicheeOnImageList,
            // onChange: info => {
            //     const status = info.file.status;
            //     if (status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }
            // },
            onChange: this.handleOnChange,
            onPreview: this.handlePreview,
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>Swichee Off</Divider>
                        <Form.Item>
                            {getFieldDecorator("image_free")(
                                <Upload {...swicheeOffUploadProps}>
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>Swichee On</Divider>
                        <Form.Item>
                            {getFieldDecorator("image_elec", {
                                rules: [
                                    {
                                        required: true,
                                        message: "이미지를 업로드해주세요!",
                                    },
                                ],
                                valuePropName: 'swicheeOnImageList',
                            })(
                                <Upload {...swicheeOnUploadProps}>
                                    {/*{this.state.swicheeOnImageList.length >= 8 ? null : uploadButton}*/}
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}

const ImageFirstForm = Form.create({})(ImageFirst);

export default ImageFirstForm;