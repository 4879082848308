import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Divider, InputNumber, Row, Slider } from "antd";

class ImageSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputCost: 0,
            inputDuration: 0,
        };
    }

    onCostChange = value => {
        // this.setState({
        //     inputCost: value,
        // });
        this.props.form.setFieldsValue({
            cost: value,
        });
    };

    onDurationChange = value => {
        // this.setState({
        //     inputDuration: value,
        // });
        this.props.form.setFieldsValue({
            duration: value,
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>Elec 가격 설정</Divider>
                        <Form.Item>
                            <Row>
                                <Col span={16}>
                                    {getFieldDecorator('price', {initialValue: 0})(
                                        <Slider
                                            min={0}
                                            max={20}
                                            onChange={this.onCostChange}
                                            value={typeof this.props.form.cost === 'number' ? this.props.form.cost : 0}
                                            tooltipVisible={false}
                                        />
                                    )}
                                    <p>1 ~ 20개</p>
                                    <p>(직접입력시 ~50000개)</p>
                                </Col>
                                <Col span={8}>
                                    {getFieldDecorator('price', {initialValue: 0})(
                                        <InputNumber
                                            min={1}
                                            max={50000}
                                            style={{marginLeft: 16}}
                                            value={this.props.form.cost}
                                            onChange={this.onCostChange}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>구매시 공개 기간 설정</Divider>
                        <Form.Item>
                            <Row>
                                <Col span={16}>
                                    {getFieldDecorator("period", {
                                        initialValue: 1
                                    })(
                                        <Slider
                                            min={1}
                                            max={15}
                                            onChange={this.onDurationChange}
                                            value={typeof this.state.inputDuration === 'number' ? this.state.inputDuration : 0}
                                        />
                                    )}
                                    <span>1 ~ 15일 이내</span>
                                </Col>
                                <Col span={8}>
                                    {getFieldDecorator("period", {
                                        initialValue: 1
                                    })(
                                        <InputNumber
                                            min={1}
                                            max={15}
                                            style={{marginLeft: 16}}
                                            value={this.state.inputDuration}
                                            onChange={this.onDurationChange}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

const ImageSecondForm = Form.create({})(ImageSecond);

export default ImageSecondForm;