import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Steps, Button, Result, message } from 'antd';
import ImageFirst from "./ImageFirst";
import ImageSecond from "./ImageSecond";
import ImageThird from "./ImageThird";
import PropTypes from 'prop-types';
import axios from "axios";
import '../ContentStyle.css';

const {Step} = Steps;

class UploadImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: 0,
        };

        this.ImageFirstRef = React.createRef();
        this.ImageThridRef = React.createRef();
    }

    next() {
        const current = this.state.current + 1;
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    handleOnListChange = ({swicheeOnImageList}) => this.setState({swicheeOnImageList});
    handleThumbnailImageChange = ({thumbnailImage}) => this.setState({thumbnailImage});

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('받은 값: ', values);
                // let thumbnail;
                let attaches = {};
                try {
                    // console.log(values.image_free.fileList[0]);
                    // console.log(values.image_free.fileList[1]);
                    attaches.thumbnail = values.thumbnail.fileList[0];
                    for (let i = 0; i < values.image_free.fileList.length; i++) {
                        attaches = Object.assign(
                            {},
                            attaches,
                            {[`free_${i + 1}`]: values.image_free.fileList[i]});
                    }
                    for (let i = 0; i < values.image_elec.fileList.length; i++) {
                        attaches = Object.assign(
                            {},
                            attaches,
                            {[`image_${i + 1}`]: values.image_elec.fileList[i]});
                    }

                    console.log('부착할 값', attaches);
                } catch (e) {
                    console.log(e);
                }
                // let uploadForm = {
                //     "content_type": "photo",
                //     "folder_idx": values.folder_idx,
                //     "paidConversion": values.paidConversion,
                //     "price": (values.price * 10).toString(),
                //     "period": (values.period).toString(),
                //     "title": (values.content),
                //     "content": (values.content),
                //     "category_idx": values.category_idx,
                //     "copyright_flag": (values.copyright_flag).toString(),
                //     // TODO: add image_x and free_x from swichee-on and swichee-off
                // };
                // console.log('변환 전 값: ', uploadForm);
                // const result = Object.assign({}, uploadForm, attaches);
                // console.log('변환한 값: ', result);
                console.log('form data start making');
                let formData = new FormData();
                formData.append('content_type', 'photo');
                formData.append('folder_idx', values.folder_idx);
                formData.append('paidConversion', values.paidConversion);
                formData.append('price', values.price * 10);
                formData.append('period', values.period);
                formData.append('title', values.content);
                formData.append('content', values.content);
                formData.append('category_idx', values.category_idx);
                formData.append('copyright_flag', values.copyright_flag);
                console.log('form data 1st: ', formData);
                try {
                    if (!values.thumbnail.fileList) {
                        formData.append('thumbnail', values.thumbnail.fileList[0].originFileObj, values.thumbnail.fileList[0].name);
                    }
                    console.log(values.image_elec.fileList[0].originFileObj);
                    for (let i = 0; i < values.image_free.fileList.length; i++) {
                        formData.append(`free_${i + 1}`, values.image_free.fileList[i].originFileObj, values.image_free.fileList[i].name);
                    }
                    for (let i = 0; i < values.image_elec.fileList.length; i++) {
                        formData.append(`elec_${i + 1}`, values.image_elec.fileList[i].originFileObj, values.image_elec.fileList[i].name);
                    }
                } catch (e) {
                    console.log(e);
                }
                console.log('form data 2nd: ', formData);
                for (let value of formData.values()) {
                    console.log(value);
                }

            } else {
                if (err.copyright_flag) {
                    message.warning("저작권 규정에 동의해주세요!");
                }
                console.log(err);
            }
        })
    };

    render() {
        const {current} = this.state;
        let steps = [
            {
                title: '이미지 업로드',
                content:
                    <ImageFirst
                        wrappedComponentRef={(form) => {
                            this.ImageFirstRef = form;
                        }}
                        form={this.props.form}/>
                ,
            },
            {
                title: '금액 및 기간 설정',
                content:
                    <ImageSecond form={this.props.form}/>
                ,
            },
            {
                title: '업로드 내용 등록',
                content:
                    <ImageThird
                        wrappedComponentRef={(form) => {
                            this.ImageThirdRef = form;
                        }}
                        form={this.props.form}/>
                ,
            },
        ];
        const contents = (
            <div>
                <Steps current={current} className='steps-line'>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                {steps.map(({title, content}, i) => (
                    <div
                        key={title}
                        className={i === this.state.current ? "foo fade-in" : "foo"}
                    >
                        <div className="content-block">
                            {content}
                        </div>
                    </div>
                ))}
                <div className="steps-action">
                    {current > 0 && (
                        <Button style={{marginRight: 8}} onClick={() => this.prev()}>
                            이전
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => {
                            if (this.ImageFirstRef.state.swicheeOnImageList.length === 0) {
                                message.warning('Swichee On 이미지를 업로드해주세요!');
                                return;
                            }
                            console.log(this.ImageThirdRef);
                            return this.next()
                        }}>
                            다음
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={this.handleSubmit}>
                            업로드
                        </Button>
                    )}
                </div>
            </div>
        );

        const loginRequired = (
            <div>
                <Result
                    status="403"
                    title="로그인 필요"
                    subTitle="우측 상단의 로그인 버튼을 눌러 로그인하실 수 있습니다."
                />
            </div>
        );

        return (
            <div className='upload-main'>
                {this.props.isLoggedIn ? contents : contents}
            </div>
        );
    }
}

UploadImage.propTypes = {
    isLoggedIn: PropTypes.bool
};

UploadImage.defaultProps = {
    isLoggedIn: false
};

export default Form.create()(UploadImage);
