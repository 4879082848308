import React, {Component} from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Upload, Button, Input, Divider, Row, Col } from "antd";

const {TextArea} = Input;

class SoundFirst extends Component {
    state = {
        video: [],
    };

    handleChange = info => {
    };

    onRadioChange = e => {
        console.log(e.target.value);
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    render() {
        // const albumImageUploadProps = {
        //     name: 'album-image',
        //     action: '',
        //     onChange: this.handleChange,
        //     showUploadList: false,
        //     listType: 'picture-card',
        //     className: 'album-image-uploader',
        // };
        const videoUploadProps = {
            action: '',
            onChange: this.handleChange,
            multiple: false,
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>앨범 이미지 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("sound-file")(
                                <Upload {...videoUploadProps} fileList={this.state.video}>
                                    <Button>
                                        <UploadOutlined /> 업로드
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                        <Divider>사운드 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("sound-file")(
                                <Upload {...videoUploadProps} fileList={this.state.video}> <Button>
                                    <UploadOutlined /> 업로드
                                </Button>
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>내용 입력</Divider>
                        <Form.Item>
                            {getFieldDecorator("sound-content")(
                                <TextArea rows={6} placeholder="사운드와 함게 들어갈 내용을 추가해보세요"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SoundFirst;