import React, {Component} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Form} from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Row, Col, Divider, Upload, Select, Input, Modal} from 'antd';
import axios from "axios";

const {TextArea} = Input;
const {Option} = Select;

const uploadButton = (
    <div style={{cursor: 'pointer'}}>
        <PlusOutlined/>
        <div className="upload-button-text">업로드</div>
    </div>
);
const category = [
    "No Choice",
    "Funding",
    "Music",
    "Sports",
    "Gaming",
    "Comedy",
    "Entertainment",
    "News & Politics",
    "Education",
    "Pets & Animals",
    "Travel & Events",
    "Science & Technology",
    "People & Blogs",
    "Fashion & Style",
    "Autos & Vehicles",
    "Film & Animation",
    "Food & Recipe",
    "Webtoon",
    "Daily"
];

class VideoThird extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            folders: [],
            thumbnailImage: [],
            selectedFolder: ''
        };
        // this.folderSelect = React.createRef();
        axios.post('https://api.swichee.me/folder/list', {}, {withCredentials: true})
            .then((response) => {
                // console.log(response,this.folderSelect);
                this.setState({folders: response.data, selectedFolder: response.data[0].folder_idx});
                // this.folderSelect.value=response.data[0].folder_idx;
                this.props.form.setFieldsValue({folder_idx: response.data[0].folder_idx});
                // response.data.map((data)=>
                //     <Option value={data.folder_idx}>{data.name}</Option>
                // )
                // console.log(this.state.folders);
                // console.log(this.state.folders[0].name);
            });

        this.handleFolder.bind(this);
    }

    // state = {
    //     thumbnailImage: [],
    // };

    handleThumbnailImageChange = ({fileList}) => {
        this.setState({thumbnailImage: fileList});
    };

    handleCancel = () => {
        this.setState({previewVisible: false});
        console.log(this)
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    handlePreview = async file => {
        console.log('handlePreview');
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    categoryRender() {
        return category.map((data, index) =>
            <Option value={index}>{data}</Option>
        );
    }

    folderRender() {
        return this.state.folders.map((data) =>
            <Option value={data.folder_idx}>{data.name}</Option>
        );
    }

    handleFolder(value) {

        console.log(this);
        this.props.form.setFieldsValue({folder_idx: value});

    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {previewVisible, previewImage, fileList} = this.state;
        const thumbnailUploadProps = {
            action: 'https://www.mocky.io/v2/5185415ba171ea3a00704eed',
            listType: 'picture',
            multiple: false,
            // fileList: this.state.thumbnailImage,
            // onChange: this.handleThumbnailImageChange,
            // beforeUpload(file) {
            //     return false;
            // }
            // onChange: info => {
            //     const status = info.file.status;
            //     if (status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }
            // }
        };
        console.log(this.state);
        return (
            <div>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>썸네일 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("thumbnail")(
                                <Upload {...thumbnailUploadProps} accept={'image/jpeg, image/png, image/gif'}
                                        onChange={this.handleThumbnailImageChange}
                                        onPreview={this.handlePreview}
                                >
                                    {this.state.thumbnailImage.length >= 1 ? null : uploadButton
                                    }
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{width: '100%'}} src={previewImage}/>
                            </Modal>
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>*/}
                    {/*    <Divider>내용 입력</Divider>*/}

                    {/*    <Form.Item>*/}
                    {/*        {getFieldDecorator("content")(*/}
                    {/*        <TextArea rows={6} placeholder="사진과 함께 들어갈 내용을 추가해보세요"/>*/}
                    {/*        )}*/}
                    {/*    </Form.Item>*/}

                    {/*</Col>*/}
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>카테고리 설정</Divider>
                        <Form.Item>
                            {getFieldDecorator("category_idx", {
                                initialValue: 0
                            })(
                                <Select style={{width: 250}}>
                                    {this.categoryRender()}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>폴더 설정</Divider>
                        <Form.Item>
                            {getFieldDecorator("folder_idx")(
                                <Select style={{width: 250}}>
                                    {this.folderRender()}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default VideoThird;
