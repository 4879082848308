import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Divider, InputNumber, Row, Slider } from "antd";

class VideoSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputCost: 0,
            inputDuration: 1,
        };
    }

    onCostChange = value => {

        this.props.form.setFieldsValue({
            price: typeof value === 'number' ? value : 0,
        });
    };

    onDurationChange = value => {
        // this.setState({
        //     inputDuration: value,
        // });
        this.props.form.setFieldsValue({
            period: typeof value === 'number' ? (value < 15 ? value : 15) : 0,
        });
        this.props.form.getFieldValue('period');
    };
    checkNext(){}
    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>Elec 가격 설정</Divider>
                        <Form.Item>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        min={0}
                                        max={20}
                                        onChange={this.onCostChange}
                                        value={typeof this.props.form.getFieldValue('price') === 'number' ? this.props.form.getFieldValue('price') : 0}
                                        tooltipVisible={false}
                                    />
                                    <span>직접입력시 최대 50,000개</span>
                                </Col>
                                <Col span={8}>
                                    {getFieldDecorator('price', {initialValue: 0})(
                                        <InputNumber
                                            min={0}
                                            max={50000}
                                            style={{marginLeft: 16}}
                                            type='number'
                                            // value={this.props.form.cost}
                                            onChange={this.onCostChange}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>구매시 공개 기간 설정</Divider>
                        <Form.Item>

                            <Row>
                                <Col span={16}>
                                    <Slider
                                        min={1}
                                        max={15}
                                        onChange={this.onDurationChange}
                                        value={typeof this.props.form.getFieldValue('period') === 'number' ? this.props.form.getFieldValue('period') : 1}
                                    />
                                    <span>1 ~ 15일 이내</span>
                                </Col>
                                <Col span={8}>
                                    {getFieldDecorator("period", {initialValue: 1})(
                                        <InputNumber
                                            min={1}
                                            max={15}
                                            style={{marginLeft: 16}}
                                            type='number'
                                            onChange={this.onDurationChange}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>

                </Row>
            </div>
        );
    }
}

export default VideoSecond;
