import React, {Component} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Divider, Upload, Select, Input } from 'antd';

const {TextArea} = Input;
const {Option} = Select;

const uploadButton = (
    <div>
        <PlusOutlined />
        <div className="upload-button-text">업로드</div>
    </div>
);

class SoundThird extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thumbnailImage: [],
        };
    }

    state = {
        thumbnailImage: [],
    };

    handleThumbnailImageChange = ({thumbnailImage}) => this.setState({thumbnailImage});

    render() {
        const {getFieldDecorator} = this.props.form;
        const thumbnailUploadProps = {
            action: 'https://www.mocky.io/v2/5185415ba171ea3a00704eed',
            listType: 'picture-card',
            // fileList: this.state.thumbnailImage,
            // onChange: this.handleThumbnailImageChange,
            onChange: info => {
                const status = info.file.status;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
            },
            multiple: false,
        };
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>썸네일 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("thumbnail-image")(
                                <Upload {...thumbnailUploadProps}>
                                    {this.state.thumbnailImage.length >= 1 ? null : uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>내용 입력</Divider>
                        <Form.Item>
                            <TextArea rows={6} placeholder="사진과 함께 들어갈 내용을 추가해보세요"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>카테고리 설정</Divider>
                        <Form.Item>
                            <Select style={{width: 250}}>
                                <Option value="one">일번</Option>
                                <Option value="two">이번</Option>
                                <Option value="three">삼번</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>폴더 설정</Divider>
                        <Select style={{width: 250}}>
                            <Option value="one">일번</Option>
                            <Option value="two">이번</Option>
                            <Option value="three">삼번</Option>
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(SoundThird);
