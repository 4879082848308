import React, {Component} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Divider, Upload, Select, Input, Switch, Steps } from 'antd';
import axios from 'axios';

const {TextArea} = Input;
const {Option} = Select;

const uploadButton = (
    <div>
        <PlusOutlined />
        <div className="upload-button-text">업로드</div>
    </div>
);

// const thumbnailImage = [];

class ImageThird extends Component {
    constructor(props) {
        super(props);

        this.state = {
            folders: [],
            thumbnailImage: {
                fileList: [],
            },
        };
    }

    handleThumbnailImageChange = (thumbnailImage) => {
        this.setState({thumbnailImage});
        console.log(this.state.thumbnailImage);
    };

    componentDidMount() {
        axios.post('https://api.swichee.me/folder/list', {}, {withCredentials: true})
            .then((response) => {
                // console.log(response);
                this.setState({folders: response.data});
                // console.log(this.state.folders);
                // console.log(this.state.folders[0].name);
            })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const thumbnailUploadProps = {
            action: 'https://www.mocky.io/v2/5185415ba171ea3a00704eed',
            listType: 'picture-card',
            defaultFileList: this.state.thumbnailImage.fileList,
            onChange: this.handleThumbnailImageChange,
            multiple: false,
        };
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>썸네일 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("thumbnail")(
                                <Upload {...thumbnailUploadProps}>
                                    {this.state.thumbnailImage.fileList.length >= 1 ? null : uploadButton}
                                    {/*{uploadButton}*/}
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>내용 입력</Divider>
                        <Form.Item>
                            {getFieldDecorator("content")(
                                <TextArea rows={6} placeholder="사진과 함께 들어갈 내용을 추가해보세요"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>카테고리 설정</Divider>
                        <Form.Item>
                            {getFieldDecorator("category_idx", {
                                initialValue: "0"
                            })(
                                <Select style={{width: 250}}>
                                    <Option value="0">No Choice</Option>
                                    <Option value="1">Funding</Option>
                                    <Option value="2">Music</Option>
                                    <Option value="3">Sports</Option>
                                    <Option value="4">Gaming</Option>
                                    <Option value="5">Comedy</Option>
                                    <Option value="6">Entertainment</Option>
                                    <Option value="7">News & Politics</Option>
                                    <Option value="8">Education</Option>
                                    <Option value="9">Pets & Animals</Option>
                                    <Option value="10">Travel & Events</Option>
                                    <Option value="11">Science & Technology</Option>
                                    <Option value="12">People & Blogs</Option>
                                    <Option value="13">Fashion & Style</Option>
                                    <Option value="14">Autos & Vehicles</Option>
                                    <Option value="15">Film & Animation</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>폴더 설정</Divider>
                        {getFieldDecorator("folder_idx")(
                            <Select style={{width: 250}} placeholder="폴더 선택">
                                {this.state.folders.map(folder => (
                                    <Option value={folder.folder_idx}>{folder.name}</Option>
                                ))}
                            </Select>
                        )}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>3시간 한정 무료 공개 설정</Divider>
                        {getFieldDecorator("paidConversion", {
                            initialValue: true,
                        })(
                            <Switch defaultChecked checkedChildren="무료" unCheckedChildren="유료"/>
                        )}
                    </Col>
                    <Col span={12}>
                        <Divider>저작권 규정 동의</Divider>
                        {getFieldDecorator("copyright_flag", {
                            initialValue: false,
                            rules: [
                                {
                                    message: '저작권 규정에 동의해주세요!',
                                    validator: (rule, value, cb) => value === true,
                                }
                            ],
                        })(
                            <Switch checkedChildren="동의" unCheckedChildren="거부"/>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(ImageThird);