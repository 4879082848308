import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Divider, InputNumber, Row, Slider } from "antd";

class BlogSecond extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputCost: 0,
            inputDuration: 0,
        }
    }

    onCostChange = value => {
        this.props.form.setFieldsValue({
            cost: value,
        });
    };

    onDurationChange = value => {
        this.props.form.setFieldsValue({
            duration: value,
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>Elec 가격 설정</Divider>
                        <Form.Item>
                            <Row>
                                <Col span={16}>
                                    {getFieldDecorator('price', {initialValue: 0})(
                                        <Slider
                                            min={0}
                                            max={20}
                                            onChange={this.onCostChange}
                                            value={typeof this.props.form.cost === 'number' ? this.props.form.cost : 0}
                                            tooltipVisible={false}
                                        />
                                    )}
                                    <p>1 ~ 20개</p>
                                    <p>(직접입력시 ~50000개)</p>
                                </Col>
                                <Col span={8}>
                                    {getFieldDecorator('price', {initialValue: 0})(
                                        <InputNumber
                                            min={1}
                                            max={50000}
                                            style={{marginLeft: 16}}
                                            value={this.props.form.cost}
                                            onChange={this.onCostChange}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {/*<Divider>구매시 공개 기간 설정</Divider>*/}
                        {/*<Form.Item>*/}
                        {/*    {getFieldDecorator("duration", {initialValue: 1}, {*/}
                        {/*        rules: []*/}
                        {/*    })(*/}
                        {/*        <Row>*/}
                        {/*            <Col span={16}>*/}
                        {/*                <Slider*/}
                        {/*                    min={1}*/}
                        {/*                    max={15}*/}
                        {/*                    onChange={this.onDurationChange}*/}
                        {/*                    value={typeof this.props.form.duration === 'number' ? this.props.form.duration : 1}*/}
                        {/*                />*/}
                        {/*                <span>1 ~ 15일 이내</span>*/}
                        {/*            </Col>*/}
                        {/*            <Col span={8}>*/}
                        {/*                <InputNumber*/}
                        {/*                    min={1}*/}
                        {/*                    max={15}*/}
                        {/*                    style={{marginLeft: 16}}*/}
                        {/*                    value={this.props.form.duration}*/}
                        {/*                    onChange={this.onDurationChange}*/}
                        {/*                />*/}
                        {/*            </Col>*/}
                        {/*        </Row>*/}
                        {/*    )}*/}
                        {/*</Form.Item>*/}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(BlogSecond);
