import React, {Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Steps, Button, Result } from 'antd';
import BlogFirst from './BlogFirst';
import BlogSecond from "./BlogSecond";
import BlogThird from "./BlogThird";
import PropTypes from 'prop-types';

import '../ContentStyle.css';

const {Step} = Steps;

class UploadBlog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: 0,
        };
    }

    next() {
        // if (this.state.current === 0) {
        // }
        const current = this.state.current + 1;
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    render() {
        const {current} = this.state;
        let steps = [
            {
                title: '블로그 업로드',
                content: <BlogFirst form={this.props.form}/>,
            },
            {
                title: '금액 및 기간 설정',
                content: <BlogSecond form={this.props.form}/>,
            },
            {
                title: '업로드 내용 등록',
                content: <BlogThird form={this.props.form}/>
            },
        ];

        const contents = (
            <div>
                <Steps current={current} className='steps-line'>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                {steps.map(({title, content}, i) => (
                    <div
                        key={title}
                        className={i === this.state.current ? "foo fade-in" : "foo"}
                    >
                        <div className="content-block">
                            {content}
                        </div>
                    </div>
                ))}
                <div className="steps-action">
                    {current > 0 && (
                        <Button style={{marginRight: 8}} onClick={() => this.prev()}>
                            이전
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()}>
                            다음
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={this.handleSubmit}>
                            업로드
                        </Button>
                    )}
                </div>
            </div>
        );

        const loginRequired = (
            <div>
                <Result
                    status="403"
                    title="로그인 필요"
                    subTitle="우측 상단의 로그인 버튼을 눌러 로그인하실 수 있습니다."
                />
            </div>
        );

        return (
            <div className='upload-main'>
                {this.props.isLoggedIn ? contents : loginRequired}
            </div>
        );
    }
}

UploadBlog.propTypes = {
    isLoggedIn: PropTypes.bool
};

UploadBlog.defaultProps = {
    isLoggedIn: false
};

export default Form.create()(UploadBlog);