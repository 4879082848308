import React, {Component} from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Result, Button, Input } from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getStatusRequest, loginFailure, loginRequest, loginSuccess, logoutRequest} from "../actions/authentication";
import "./AuthStyle.css";
import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import axios from "axios";

// const responseGoogle = (response) => {
//     axios.post('https://api.swichee.me/folder/list', {}, {withCredentials: true})
//         .then((response) => {
//             // console.log(response);
//             this.setState({folders: response.data});
//             // console.log(this.state.folders);
//             // console.log(this.state.folders[0].name);
//         }).;
//     console.log(response);
// }

class Authentication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        };
    }


    handleChange = e => {
        let nextState = {};
        nextState[e.target.name] = e.target.value;
        this.setState(nextState);
    };

    handleLogin = () => {

        this.props.handleLogin('id',{id:this.state.username,password:this.state.password}).then(
            (success) => {
                if (!success) {
                    this.setState({
                        password: ''
                    });
                }
            }
        )
    };

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleLogin();
        }
    };



    handleGoogleFailure(error, details) {
        console.log(error, details);
    }

    render() {
        const loginView = (
            <div>
                <div className="content-block">
                    <div style={{maxWidth: '700px'}}>

                        <div>
                            <Input
                                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                                name="username"
                                placeholder="아이디"
                                className="validate"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.username}
                                onKeyPress={this.handleKeyPress}
                            />
                        </div>
                        <div>
                            <Input
                                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                                name="password"
                                placeholder="비밀번호"
                                type="password"
                                className="validate"
                                onChange={this.handleChange}
                                value={this.state.password}
                                onKeyPress={this.handleKeyPress}
                            />
                        </div>
                        <div>
                            <Button type="primary" style={{width:'300px'}} onClick={()=>this.props.handleLogin('id',{id:this.state.username,password:this.state.password})}>Continue</Button>
                        </div>

                        <div style={{marginTop:10}}>
                            <span style={{fontSize:20}}>or</span>
                        </div>
                        <GoogleLogin
                            clientId="209441724701-gsk5n6p940bs6vp7e116476dmvgs7cns.apps.googleusercontent.com"
                            onSuccess={(data)=>{this.props.handleLogin('google',data).then(
                                (success) => {
                                    if (!success) {
                                        this.setState({
                                            password: ''
                                        });
                                    }
                                }
                            )}}
                            onFailure={this.handleGoogleFailure}

                            cookiePolicy={'single_host_origin'}

                            buttonText={'Login with Google'}
                            className={'asdmk'}
                            theme="dark"
                        />
                        <br/>

                        <FacebookLogin
                            appId="264301677399649"

                            icon="fa-facebook-official"
                            autoLoad={false}
                            fields="name,email,picture"
                            // onClick={this.handleGoogleSuccess}
                            callback={(data)=>{this.props.handleLogin('fb',data).then(
                                (success) => {
                                    if (!success) {
                                        this.setState({
                                            password: ''
                                        });
                                    }
                                }
                            )}}
                            onFailure={this.handleGoogleFailure}
                            cssClass="facebook-button-class"
                            textButton="asdads"
                            render={renderProps => (
                                <button className="facebook-button-class" onClick={renderProps.onClick}>
                                    <link rel="stylesheet"
                                          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                                    <span style={{
                                        margin: '8px',
                                    }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 216 216"
                                         className="_5h0m" color="#FFFFFF">
                                        <path fill="#FFFFFF" d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"></path>
                                    </svg>
                                              </span>
                                    <span className={'text'}>Login with Facebook</span>
                                </button>
                            )}
                        />
                        <br/>
                    </div>
                </div>
            </div>
        );

        const loginRequired = (
            <div className="content-block">
                <Result
                    status="403"
                    title="로그인 필요"
                    subTitle="우측 상단의 로그인 버튼을 눌러 로그인하실 수 있습니다."
                />
            </div>
        );

        const myPageContents = (
            <div className="content-block">
                <p>로그인된 유저: {this.props.currentUser}</p>
                <Button onClick={this.handleLogout}>로그아웃</Button>
            </div>
        );

        const myPageView = (
            <div>
                {this.props.isLoggedIn ? myPageContents : loginRequired}
            </div>
        );

        return (
            <div>
                {this.props.mode ? loginView : myPageView}
            </div>
        );
    }
}

Authentication.propTypes = {
    mode: PropTypes.bool,
    onLogin: PropTypes.func
};

Authentication.defaultProps = {
    mode: true,
    onLogin: (id, pw) => {
        console.error('login function not defined');
    }
};

const mapStateToProps = state => {
    return {
        status: state.authentication.status
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getStatusRequest: () => {
            return dispatch(getStatusRequest());
        },
        logoutRequest: () => {
            return dispatch(logoutRequest());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
