import React, {Component} from 'react';
import { Authentication } from '../components';
import { connect } from 'react-redux';
import {handleLogin} from "../actions/authentication";

class Login extends Component {
    // loginRequest = (id, pw) => {
    //     return this.props.loginRequest(id, pw).then(
    //         () => {
    //             if(this.props.status === "SUCCESS") {
    //                 // let loginData = {
    //                 //     isLoggedIn: true,
    //                 //     username: id
    //                 // };
    //                 // document.cookie = 'ci_session=' + btoa(JSON.stringify(this.props.status));
    //                 this.props.history.push('/');
    //                 console.log('success');
    //                 return true;
    //             } else {
    //                 console.log('failed');
    //                 return false;
    //             }
    //         }
    //     )
    // };


    handleLogin = (type, data) => {
        return this.props.handleLogin(type,data).then(
            (data)=>{
                console.log(this.props,data);
                if(this.props.status === "SUCCESS") {
                    // let loginData = {
                    //     isLoggedIn: true,
                    //     username: id
                    // };
                    // document.cookie = 'ci_session=' + btoa(JSON.stringify(this.props.status));
                    this.props.history.push('/');
                    console.log('success',this.props.status);
                    return true;
                } else {
                    console.log('failed',this.props.status);
                    return false;
                }
            }
        )
    };

    render() {
        return (
            <div>
                <Authentication mode={true} onLogin={this.handleLogin} handleLogin={this.handleLogin} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    console.log(state);
    return {
        status: state.authentication.login.status
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // loginRequest: (id, pw) => {
        //     return dispatch(loginRequest(id, pw));
        // },
        handleLogin: (type, data) =>{
            return dispatch(handleLogin(type, data));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
