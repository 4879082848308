import React, {Component} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Upload, Row, Col, Input, Divider } from 'antd';

const {TextArea} = Input;

const uploadButton = (
    <div>
        <PlusOutlined />
        <div className="upload-button-text">업로드</div>
    </div>
);

class BlogFirst extends Component {
    state = {
        backgroundImage: [],
    };

    render() {
        const backgroundUploadProps = {
            action: '//jsonplaceholder.typicode.com/posts/',
            listType: 'picture-card',
            multiple: false,
            onChange: info => {
                const status = info.file.status;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
            }
        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>배경화면 업로드 (선택)</Divider>
                        <Form.Item>
                            {getFieldDecorator("background-image")(
                                <Upload {...backgroundUploadProps}>
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>블로그 내용 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("blog-title")(
                                <Input size="large" placeholder="제목"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("swichee-off-blog-content")(
                                <TextArea rows={6} placeholder="블로그 내용 (스위치 오프)"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("swichee-on-blog-content")(
                                <TextArea rows={6} placeholder="블로그 내용 (스위치 온)"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(BlogFirst);