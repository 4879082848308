import React from 'react';

import './SwicheeIntro.css';
import {Col, Row} from "antd";

const SwicheeIntro = () => {
    return (
        <div>
            <div className="card-color-intro">
                <div id="title">
                    Let me introduce about Swichee!
                </div>
                <span id="content">
                    Connection between Artists
                </span>
            </div>
            <div className="card-default">
                <div id="title">
                    What is Swichee?
                </div>
                <div id="content">
                    Swichee is the platform that Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                </div>
                <div className="card-default">
                    <Row>
                        <Col span={6}>
                            Elec Image
                        </Col>
                        <Col span={18}>
                            <div id="title">
                                What is Elec?
                            </div>
                            <div id="content">
                                Elec is our Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore
                                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SwicheeIntro;
