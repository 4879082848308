import React from 'react';

import './Home.css';
import './App.css';
import logo from '../public/logo.png';
const Home = () => {
    return (
        <div className="App">
            <div className="App-body">
                Coming Soon!
            </div>
            {/*<div className="App-footer">*/}
            {/*    <ul className="Nav-items">*/}
            {/*        <li className="Nav-item">*/}
            {/*            <a className="Legal_link" href="https://swichee.me/legal/function">서비스 이용약관</a>*/}
            {/*        </li>*/}
            {/*        <span className="Txt_bar">l</span>*/}
            {/*        <li className="Nav-item">*/}
            {/*            <a className="Legal_link" href="https://swichee.me/legal/privacy">개인정보 수집 및 이용에 대한 안내</a>*/}
            {/*        </li>*/}
            {/*        <span className="Txt_bar">l</span>*/}
            {/*        <li className="Nav-item">*/}
            {/*            <a className="Legal_link" href="https://swichee.me/legal/community">커뮤니티 가이드라인</a>*/}
            {/*        </li>*/}
            {/*        <span className="Txt_bar">l</span>*/}
            {/*        <li className="Nav-item">*/}
            {/*            <Link className="Legal_link" to="/legal/location">위치정보 이용약관</Link>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
    );
};

export default Home;
